import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout/layout"
import favicon from "../../images/favicon.ico"

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        Terms - EzriRx.com - Marketplace Platform for 65,000+ Pharmaceuticals
      </title>
      <meta name="keywords" content="ezrirx, pharmacy purchasing platform" />
      <meta
        name="description"
        content="EzriRx allows pharmacies to compare drug prices offered by many suppliers. This page has our Terms Of Service."
      />
      <link rel="canonical" href="https://www.ezrirx.com/legal/terms/" />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <Layout>
      <div className="body">
        <section className="hero-banner hero-banner--only-text" id="hero-about">
          <div className="contents">
            <div className="hero-banner__inner">
              <div className="hero-content">
                <h1>Terms of service</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content">
          <div className="contents">
            <div className="single-content-text">
              <p>
                <i>
                  Thank you for choosing EzriRx. By registering for an account
                  on www.EzriRx.com (“the Site”) or engaging in any transactions
                  on the Site, you are confirming that you have read, understand
                  and consent to these Terms of Service. If you do not consent
                  to these Terms of Service, you must not create or use an
                  account or engage in any transactions on the Site.
                </i>
              </p>

              <p>
                <i>
                  ExriRx reserves the right to change or supplement these Terms
                  of Service at any time in its sole discretion and your
                  continued use of the Site after any such amendment shall
                  constitute your affirmative consent to the new terms. Fee
                  schedules, Site instructions, and other written guidance
                  provided by EzriRx shall be considered a part of, and shall be
                  deemed to be, incorporated into these Terms of Service.
                </i>
              </p>

              <p>
                <i>
                  If you have any questions about the Site or these Terms of
                  Service, please contact us at support@ezrirx.com or
                  718-502-6610
                </i>
              </p>

              <p>
                <i>Last updated: September 1, 2019</i>
              </p>

              <h4 style={{ marginBottom: "25px" }}>1. Definition</h4>

              <p>
                <i>
                  As used in these Terms of Service or anywhere on the Site, the
                  following terms shall have the following meanings:
                </i>
              </p>

              <ol type="a">
                <li>
                  “Buyer” means a party that intends to purchase, or does
                  purchase, any Product from any Seller or Sellers on the Site.
                </li>
                <li>
                  “EzriRx,” “us,” and “we” all mean EzriRx, LLC, a limited
                  liability company organized under the laws of the State of
                  Delaware and, if applicable, its employees and agents.
                </li>
                <li>
                  “Lawful Transaction” means a transaction that complies in all
                  respects with any and all applicable local, state and federal
                  laws, rules and regulations, including without limitation, the
                  Federal Food, Drug and Cosmetic Act, 21 U.S.C. §301, et seq.,
                  the Prescription Drug Marketing Act of 1987, 21 U.S.C. §351,
                  et seq., the Drug Supply Chain Security Act, 21 U.S.C. §360eee
                  et seq., the Controlled Substances Act, 21 U.S.C. §801, et
                  seq., and their respective implementing regulations.
                </li>
                <li>
                  “Party,” “Parties” or “you” mean a Seller and/or a Buyer.
                </li>
                <li>
                  “Product” means any prescription drug, medical device,
                  over-the-counter product or sundry item including, without
                  limitation, any article intended for use in the diagnosis,
                  cure, mitigation, treatment, or prevention of disease in man
                  or other animals, or any articles other than food intended to
                  affect the structure or any function of the body of man or
                  other animals, or articles intended for use as a component of
                  any article specified above.
                </li>
                <li>
                  “Seller” means to a party intending to sell, or that does
                  sell, any Product to any Buyer or Buyers on the Site.
                </li>
                <li>
                  “Services” means any activity conducted by EzriRx on or
                  through the Site or the EzriRx platform.
                </li>
                <li>
                  “Third Party” means an entity that is not owned or controlled
                  by any Party, or by EzriRx.
                </li>
                <li>
                  “Transaction Documents” means the Transaction Information,
                  Transaction History and Transaction Statement required by
                  section 582 of the Drug Supply Chain Security Act.
                </li>
                <li>
                  “Transaction Value” means the sum of all charges Buyer agrees
                  to pay and Seller agrees to accept in exchange for Products in
                  a single transaction, including any shipping and handling
                  charges. “Transaction Value” does not include any taxes.
                </li>
              </ol>

              <h4 style={{ marginBottom: "25px" }}>2. Function of the Site</h4>

              <ol type="a">
                <li>
                  EzriRx provides Services and an online platform for the
                  Parties to negotiate and conduct sales of Pharmacy Products.
                  EzriRx will always strive to provide you with the best service
                  in the industry. But, it’s important for you to understand
                  what we do and what we are and are not responsible for.
                </li>
                <li>
                  In exchange for using the Site and taking advantage of our
                  Services, Sellers agree to pay EzriRx a fee based on the
                  Transaction Value of each transaction. Our fees are separate
                  and apart from the cost of the Products. We reserve the right
                  to change our fees at any time.
                </li>
                <li>
                  By registering for the Site and taking advantage of the
                  Services offered by EzriRx, you authorize Third Party payment
                  processors of our choosing to process payments and fees on
                  your behalf, including payment, receipt, and transfer of funds
                  to the Parties and EzriRx. You understand that we may deduct
                  funds from bank accounts or credit cards to which you give us
                  access to pay the Transaction Value of any transaction
                  occurring on the Site.
                </li>
                <li>
                  We serve as an online platform for Buyers and Sellers to
                  engage in Lawful Transactions, and, with our Third-Party
                  payment processors, we assist the Parties in invoicing and
                  payments. However, except in certain limited transactions
                  where we act as a Buyer or Seller, we do not take ownership of
                  any Products, we are not parties to any transaction, and we
                  are not responsible for any mistakes in order placement, in
                  shipment, or for returns or credits. All sales are subject to
                  not only these Terms of Service, but also any applicable
                  policies and procedures of the Seller.
                </li>
              </ol>

              <h4 style={{ marginBottom: "25px" }}>3. Eligibility</h4>

              <ol type="a">
                <li>
                  By registering for an account and accessing the Site, you
                  represent and warrant that you have the legal actual
                  authority, right, and the power to enter into contracts and to
                  bind the business entity seeking registration.
                </li>
                <li>
                  By registering for an account and accessing the Site, you
                  represent and warrant that all of the information you provide
                  is and will remain truthful and not misleading.
                </li>
                <li>
                  By registering for an account and accessing the Site, Buyers
                  understand and agree that Sellers may impose additional
                  requirements, including background checks, licensing
                  verifications, credit reports, credit applications, and other
                  restrictions on a Buyer’s ability to purchase products on the
                  Site. We are not responsible for determining these
                  requirements or evaluating the information provided.
                </li>
                <li>
                  We reserve the right, in our sole and exclusive discretion, to
                  suspend or terminate the account privileges and access to the
                  Site of any Party at any time with or without notice.
                </li>
              </ol>

              <h4 style={{ marginBottom: "25px" }}>4. Seller Obligations</h4>

              <ol type="a">
                <li>
                  Sellers may take payments from Buyers through Automatic
                  Clearing House (ACH) systems, or through credit card
                  transactions utilizing our designated Third-Party payment
                  processors.
                </li>
                <li>
                  In order to conduct credit card transactions on the Site, all
                  Sellers must register with our designated Third-Party payment
                  processors. EzriRx will pay a portion of the sign-up fees in
                  accordance with the Fee Schedule.
                </li>
                <li>
                  In order to conduct ACH payment transactions, Sellers must
                  open a "White Label" account provided by our designated
                  Third-Party payment processors.
                </li>
                <li>
                  Sellers shall make payments according to the terms outlined in
                  the Fee Schedule.
                </li>
                <li>
                  All Parties must accept our designated Third-Party payment
                  processors’ respective Terms of Service and Privacy Policy.
                </li>
                <li>
                  Any funds held by our designated Third-Party payment
                  processors are held by the Third- Party payment processors’
                  financial institution partners as set out in the Third-Party
                  payment processors’ Terms of Service. We do not hold any
                  funds. You authorize EzriRx to share your identity and account
                  data, including account balances, with the Third-Party payment
                  processors for the purposes of opening and supporting your
                  account, and you are responsible for the accuracy and
                  completeness of that data. You understand that you will access
                  and manage your account through the Site, and notifications
                  from the payment processors about your account will be sent
                  by/to EzriRx, not by any Third-Party payment processor.
                </li>
                <li>
                  Although we will make commercially reasonable efforts to
                  collect undisputed amounts due from Buyers for completed
                  Lawful Transactions occurring on the Site, Sellers are
                  ultimately solely responsible for enforcing all contractual or
                  other rights they may have against Buyers for default in
                  payment. We will reasonably cooperate with Sellers in their
                  efforts to collect amounts due from Buyers, but we will not
                  file legal action nor be liable for any costs, including
                  attorneys’ fees or court costs, arising from collection
                  efforts undertaken by Sellers.
                </li>
                <li>
                  Sellers shall be solely responsible for collecting and
                  remitting any applicable taxes, fees, duties or other
                  governmental charges to the appropriate authorities.
                </li>
                <li>
                  EzriRx will provide customer support for your account
                  activity, and we can be reached at support@ezrirx.com or
                  718-502-6610.
                </li>
                <li>
                  Seller must provide EzriRx complete and accurate Transaction
                  Documents for every Transaction involving prescription drugs
                  completed on the Site. Seller represents and warrants to
                  ExriRx and the Buyer that the Transaction Documents will
                  complete, accurate and in full compliance with all applicable
                  laws and regulations. ExriRx will store the Transaction
                  Documents provided by Seller on the Site and make such
                  Transaction Documents available to the Buyer involved in the
                  transaction for a period of no less than 3 years from the date
                  of the Transaction. Notwithstanding the foregoing, Seller
                  shall be solely responsible for complying with all applicable
                  provisions of state, federal or local laws relating to the
                  Transaction Documents, pedigrees or similar documents required
                  in relation to transactions occurring on the Site.
                </li>
              </ol>

              <h4 style={{ marginBottom: "25px" }}>5. Buyer Obligations</h4>

              <ol type="a">
                <li>
                  Buyer agrees to pay Seller the agreed upon Transaction Value,
                  plus any applicable taxes or fees, arising from the sale of
                  any Product on the Site. All offers and orders made on the
                  Site are final and binding immediately upon acceptance by the
                  Seller.
                </li>
                <li>
                  If Buyer fails to pay the Transaction Value to the Seller
                  within 1 day of the completion of the transaction, Buyer
                  hereby authorizes EzriRx to charge Buyer’s credit card, debit
                  card, or bank account via ACH for the Transaction Value.
                  EzriRx may obtain updated credit card information from card
                  networks, issuers, or other third-party sources.
                </li>
              </ol>

              <h4 style={{ marginBottom: "25px" }}>6. Prohibited Acts</h4>

              <ol type="a">
                <li>
                  No Party shall engage in any transactions on the Site other
                  than Lawful Transactions.
                </li>
                <li>
                  No Party shall use the Site or the Services except in
                  accordance with these Terms of Use, as amended from time to
                  time in our sole discretion.
                </li>
                <li>
                  No Party shall violate any federal or state laws and
                  regulations in connection with the purchase, sale or
                  distribution of any Product on the Site. No Party shall offer
                  for sale, sell, purchase or distribute any Product on the Site
                  that is not in full compliance with all applicable federal and
                  state laws and regulations.
                </li>
                <li>
                  No Party shall engage in any transaction for any Product
                  unless all parties involved in the transaction meet the
                  applicable definition of an Authorized Trading Partner under
                  federal law. The Parties are responsible for providing and
                  receiving all documentation, including the required
                  Transaction Information, Transaction History and Transaction
                  Statement associated with each transaction.
                </li>
                <li>
                  No Party shall purchase or sell Products through the Site with
                  the intent of selling, using, dispensing, diverting the
                  Products for any unlawful purposes, or for any purpose that
                  are likely to have the potential of harming EzriRx, its
                  ongoing business, future business prospects, employees, or its
                  reputation.
                </li>
                <li>
                  No Party shall engage in, make an offer to, or accept an offer
                  to divert any transaction involving Products originating on
                  the Site to any forum other than the Site, including any
                  direct transactions between a Buyer and a Seller outside of
                  the Site, or use any form or method of payment other than as
                  established on the Site. Any Party engaging in such conduct
                  will have its account privileges immediately terminated, and
                  will be subject to a claim for damages.
                </li>
                <li>
                  No Party shall offer, buy or sell any Product, or post any
                  content or material on the Site, that infringes any
                  intellectual property right of any other person. No party
                  shall reproduce, copy or download any content from the Site
                  including photographs, price files or other data.
                </li>
                <li>
                  No Party shall knowingly engage in any conduct that causes or
                  has the potential to cause monetary or reputational harm to
                  EzriRx, including but not limited to, its ongoing business,
                  future business prospects, employees, or its reputation.
                </li>
                <li>
                  Should a Seller’s registration for EzriRx Services be
                  cancelled or terminated for any reason, the Seller shall
                  immediately discontinue all use of the Third-Party payment
                  processors’ system associated with the Site, and shall cancel
                  its account with such Third- Party payment processors within
                  30 calendar days of the cancellation or termination of its
                  registration.
                </li>
                <li>
                  No Party shall disclose its EzriRx account information,
                  including its log-in credentials or password, to any person or
                  entity for any purpose other than to conduct such Party’s
                  business on the Site. No Party may authorize any person or
                  entity, other than employees or agents of such Party, to
                  access the Site or use the Services.
                </li>
              </ol>

              <h4 style={{ marginBottom: "25px" }}>
                7. Termination of Services
              </h4>

              <ol type="a">
                <li>
                  We reserve the right to suspend or cancel any transactions or
                  any Party account in our sole discretion.
                </li>
                <li>
                  EzriRx reserves the right to refuse Services to any Party.
                </li>
                <li>
                  If your account is terminated or suspended, you are still
                  required to complete any transactions you entered into, and
                  fulfill any other obligations you incurred prior to the date
                  of such termination or suspension.
                </li>
              </ol>

              <h4 style={{ marginBottom: "25px" }}>
                8. Disclaimers and Limitation of Liability
              </h4>

              <ol type="a">
                <li>
                  EzriRx only provides a platform for the Parties to negotiate
                  and conduct Lawful Transactions. EzriRx is not involved in or
                  a party to any transaction between any Party. EzriRx does not
                  and cannot guarantee the quality, legality or safety of
                  Products sold and advertised through the Site. We cannot
                  guarantee the completeness or accuracy of any Transaction
                  Documents. We are not responsible for any damages of any kind
                  arising from, or relating to, any of the Products sold through
                  the Site. EzriRx does not guarantee the truthfulness or
                  accuracy of marketing materials used by the Parties. EzriRx
                  cannot guarantee the Parties’ identities or their legal or
                  financial ability to conduct or complete any transaction.
                </li>
                <li>
                  EzriRx does not warranty that Products purchased on the Site
                  will be eligible for reimbursement from third-party payors.
                </li>
                <li>
                  By using the Site and taking advantage of the Services, you
                  agree, on behalf of yourself, your employees, agents, parent
                  companies, subsidiaries and any other entity associated with
                  you or acting on your behalf, that EzriRx shall not be liable
                  or responsible for any damages or any kind, including lost
                  profits, lost opportunities or any other damages of any
                  nature, arising from or relating to disruptions to, breakdowns
                  of, delays in or errors associated with the Site, the EzriRx
                  platform, the Services, any disruptions, breakdowns or delays
                  of any Third Party payment processor’s service, or in
                  connection with any transaction involving any Buyer or Seller.
                </li>
                <li>
                  By registering for and using the Site, you grant EzriRx a
                  non-exclusive, fully sub- licensable, transferable,
                  royalty-free, worldwide license to use any content and
                  materials that you post on the Site. In particular, we may use
                  any content and material you post to the Site to promote the
                  Site or our Services. ExriRx shall not be liable for any use
                  or display of any content or intellectual property posted on
                  the Site by any Party.
                </li>
              </ol>

              <h4 style={{ marginBottom: "25px" }}>9. Miscellaneous</h4>

              <ol type="a">
                <li>
                  Governing Law and Exclusive Jurisdiction – The Parties agree
                  that any lawsuit or other claim against EzriRx arising under
                  these Terms of Service, relating to the use of the Site, the
                  Services or otherwise in connection with any transaction
                  posted or occurring on the Site shall be brought only in the
                  state courts located in Ocean County in the State of New
                  Jersey. New Jersey law shall apply to any such lawsuit or
                  claim, without regard to choice of law principles. The Parties
                  agree and acknowledge that such courts are a proper forum for
                  all such lawsuits or claims, and irrevocably consent to the
                  jurisdiction of New Jersey courts, and waive any argument that
                  such courts lack jurisdiction over them or constitute an
                  inconvenient forum.
                </li>
                <li>
                  Compliance with Anti-Kickback Statute – Federal law may
                  require the disclosure by Buyers of discounts, rebates,
                  rewards or other reductions in price received, directly or
                  indirectly, as a result of transactions occurring on the Site
                  in claims, charges or reports made to federal healthcare
                  programs, including Medicare and Medicaid. By accessing the
                  Site and engaging in transactions, Buyers acknowledge this
                  obligation and warrant and represent that they will properly
                  disclose and report, and appropriately reflect any reductions
                  in price received and all amounts paid by Buyer (including all
                  rebates or rewards) as required by applicable state and
                  federal laws and regulations, including 42 U.S.C. §1320a-7b
                  and the “safe harbor” regulations at 42 C.F.R. §1001.952(h) –
                  (j).
                </li>
                <li>
                  Indemnification -- You agree to defend and indemnify us
                  against, and hold us harmless from, any and all claims, suits,
                  actions, judgments, fines and penalties, including claims for
                  attorneys’ fees or costs, arising from or relating to any
                  prohibited act described in these Terms of Service, any other
                  violation of this Terms of Service, or any other unlawful
                  conduct.
                </li>
                <li>
                  Status of the Parties – The Parties and EzriRx are each
                  independent contractors and nothing herein or on the Site
                  shall be deemed to create a joint venture or agency
                  relationship of any kind.
                </li>
                <li>
                  Entire Agreement – These Terms of Service constitute the
                  entire agreement between EzriRx and the Parties and no other
                  oral or written agreement or representation concerning the
                  Site shall be binding on EzriRx unless it is signed by an
                  officer of EzriRx.
                </li>
                <li>
                  These Terms of Service may be amended at any time by EzriRx in
                  its sole discretion by posting an amended version on the Site.
                </li>
              </ol>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </>
)
